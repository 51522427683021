import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'

const ShinkapuPage: React.FC<PageProps> = ({ location, pageContext }) => (
  <Layout id="content" subtitle="新株予約権者優待のお知らせ" pageContext={pageContext}>
    <section id="top">
      <Header location={location} pageContext={pageContext} />
    </section>

    <div className="content-wrap">
      <section className="privacy">
        <article>
          <h2>新株予約権者優待のお知らせ</h2>
          <p>&nbsp;</p>
          <p>
          弊社では、新株予約権者の皆様のご支援に感謝するとともに、弊社サービスへの理解を深めていただき、より多くの方にご利用いただくための優待サービスを提供しております。
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>【優待の基準日】</li>
          </ul>
          <p>
          2024年5月末日
          </p>
          <ul>
            <li>【優待内容】</li>
          </ul>
          <ol>
            <p>
            ・1個〜18個：「xpression camera」プレミアムプラン1ヶ月無料（約7,000円分）
            </p>
            <p>
            ・19個〜36個：「xpression camera」プレミアムプラン1年間無料（約75,000円分）
            </p>
            <p>
            ・37個以上：「xpression camera」プレミアムプラン生涯無料（約225,000円分）
            </p>
          </ol>
          <ul>
            <li>【お申し込み方法】</li>
          </ul>
          <p>
          ・基準日経過後、メールにてお申し込みのご案内をさせていただきます。
          </p>
          <p>
          ・お申し込み時のお名前・ご住所と新株予約権原簿のお名前・ご住所を照合してご利用の確認をさせていただきます。
          </p>
          <p>
          ・新株予約権原簿のお名前やご住所に変更がある場合はお手続きをいただいてからお申し込みください。
          </p>
          <ul>
            <li>【ご注意】</li>
          </ul>
          <p>
          ・お一人様1回限りの優待になります。
          </p>
          <p>
          ・当優待内容は今期（2024年5月期）の内容になります。
          </p>
          <p>
          ・優待内容は変更や廃止になる場合もあります。予めご了承ください。
          </p>
        </article>
      </section>
    </div>

    <Footer location={location} />
  </Layout>
)

export default ShinkapuPage
